import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const JobOpenings = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pt-lg-30 pb-9 pb-lg-12 bg-default-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-8 mb-lg-10 heading-color">
                    Software Engineer - Backend Development
                  </h2>
                  <p className="gr-text-7 mb-0 mb-lg-13 text-color-opacity">Full Time, New York</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-6 pb-lg-17 bg-default-6">
          <Container>
            <Row className="justify-content-center">
              <Col xl="8" lg="9">
                <div className="single-block mb-12 mb-lg-15">
                  <p className="gr-text-9 text-color-opacity mb-9">
                    AIN Technologies is a product engineering company working with innovative startups and enterprises. 
                    We have provided full-stack product development for startups across the globe building products in the cloud-native, data engineering, 
                    B2B SaaS, IoT & Machine Learning space. 
                    Our team of elite software engineers solve hard technical problems while transforming customer ideas into successful products.
                  </p>
                </div>
                <div className="single-block mb-12 mb-lg-15">
                  <h3 className="gr-text-6 heading-color fw-bold mb-9">
                    What you’ll be doing
                  </h3>
                  <p className="gr-text-9 text-color-opacity mb-0">
                    Design, build, test, and ship customer-facing features & products using modern web-app engineering stack. Our teams use Python (Django), .Net Framework, PostgreSQL, Elasticsearch, Redis, data engineering tooling, Kafka and AWS.
                    Participate in engineering discussions, technical evaluations, design reviews and retrospectives.
                    Provide database schema, API designs and micro-service implementations.
                    Mentor team members and peer review code. Focus on best practices for engineering processes and code quality.
                  </p>
                </div>
                <div className="single-block mb-12 mb-lg-14">
                  <h3 className="gr-text-6 heading-color fw-bold pb-3">
                    Skills &amp; Qualifications
                  </h3>
                  <ul className="list-unstyled">
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                        3 years’ experience with EMR systems preferably in a clinical setting. (ideally with Django, .NET Framework, DRF, Flask, Tornado)
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                        5 years expert level experience with FHIR implementation on the Microsoft .NET platform in C#.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                        3 years’ experience with ASP.NET programming. • Familiar with HL7, XML, Json, job scheduling, batch processing.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                        Experience with implementing FHIR onto pre-existing web applications. Capable of FHIR project management to implement FHIR from the ground up
                    </li>

                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Strong understanding of software engineering best practices, including unit testing, code reviews, 
                      design documentation, debugging, troubleshooting, and agile development
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Experience in at least one cloud platform (Amazon Web Service or Google Cloud Platform)
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Demonstrates ability to communicate effectively with the
                      rest of the team
                    </li>
                  </ul>
                </div>
                <div className="single-block mb-12 mb-lg-15">
                  <h3 className="gr-text-6 heading-color fw-bold mb-9">
                    How to apply
                  </h3>
                  <p className="gr-text-9 text-color-opacity mb-9">
                    We want to hire smart, curious and ambitious folks so please reach out even if you do not have all of the requisite experience. We are looking for engineers with the potential to grow!
                  </p>
                  <a href="mailto:info@ainintel.com" className="btn btn-primary mt-3 mt-lg-10">
                    Apply for this job
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default JobOpenings;
